.cta {
	margin-top: 20px;
	background: $colorYellow;
	padding-left: 30px;
	padding-right: 30px;
	font-weight: bold;
	// border-top: 1px solid $color_main3;
	// border-bottom: 1px solid $color_main3;
	// background: url('../img/menu/tab_select.png') center top no-repeat;

	div {
		text-align: center;
	}

	p {
		text-align: center;
	}

	.title {
		color: $colorHannaBlue;
		margin-bottom: 20px;
	}
}
