.menu {
	width: 240px;
	top: 0px;
	height: 100%;
	background-color: $color_main10;
}


.menu__list {
	@include breakpoint (800px) {
		display: block;
	}
}


.menu__item {
	float: left;
	width: 100%;
}

.menu__item.level-1 {
	background-color: $colorBlue;
	
	> a {
		font-size: 16px;
		line-height: 25px;
		display: block;
		line-height: 1.3;
		color: $colorWhite;
		padding: 32px 40px 32px 20px;
		cursor: pointer;
		float: left;
		width: 100%;
		border-bottom: 1px solid $colorWhite;
		font-weight: bold;
		font-size: 16px;
		color: $colorWhite;
		text-decoration: none;
		width: 100%;
		
		&:hover {
			color: $colorYellow;
		}
	}
	
	&.is-active {
		> a {
			background-color: $colorYellow;
			color: $colorBlue;
			border-bottom: 1px solid #FFF;
			
			&:hover {
				color: #2D3F65;
			}
		}
	}
	
	&.is-current {
		> a {
			background-color: $colorYellow;
			color: $colorBlue;
			border-bottom: 1px solid #FFF;
		
			&:hover {
				color: #2D3F65;
			}
		}
	}
}

.menu__item.level-1.is-parent {
	> a {
		background-image: url('../img/menu-marker-parent-white.png');
		background-position: right 20px center;
		background-repeat: no-repeat;

		&:hover {
			background-image: url('../img/menu-marker-parent-yellow.png');
			background-position: right 20px center;
			background-repeat: no-repeat;
		}
	}
	&.is-active, &.is-current {
		> a {
			background-image: url('../img/menu-marker-parent-blue.png');
			background-position: right 20px center;
			background-repeat: no-repeat;
		}
	}
}


.menu__item.level-2 {
	background-color: $color_main3;
	
	> a {
		display: block;
		line-height: 1.3;
		color: $colorWhite;
		padding: 20px 40px 20px 28px;
		cursor: pointer;
		float: left;
		width: 100%;
		border-bottom: 1px solid $colorWhite;
		font-weight: bold;
		font-size: 14px;
		text-decoration: none;
		width: 100%;
		
		&:hover {
			color: $colorYellow;
		}
	}
	
	> ul {
		float: left;
		padding: 10px 0 16px;
		background-color: $color_main10;
	}

	&.is-active, &.is-current {
		> a {
			background-color: transparent;
			color: $colorYellow;
		}
	}
}


.menu__item.level-2.is-parent {
	> a {
		background-image: url('../img/menu-marker-parent-white.png');
		background-position: right 20px center;
		background-repeat: no-repeat;

		&:hover {
			background-image: url('../img/menu-marker-parent-yellow.png');
			background-position: right 20px center;
			background-repeat: no-repeat;
		}
	}
	&.is-active, &.is-current {
		> a {
			background-image: url('../img/menu-marker-parent-yellow.png');
			background-position: right 20px center;
			background-repeat: no-repeat;
		}
	}
}


.menu__item.level-3 {
	
	> a {
		text-decoration: none;
		display: block;
		line-height: 1.3;
		color: $colorBlue;
		padding: 10px 40px 10px 32px;
		font-size: 14px;
		font-weight: bold;
		float: left;
		clear: both;
		width: 100%;
		
		&:hover {
			text-decoration: underline;
		}
	}
	
	&.is-active, &.is-current {
		> a {
			background-color: transparent;
			color: $colorBlue;
			border-bottom: none;
			font-weight: bold;
			text-decoration: underline;
		}
	}
}
	
/*
.menu__item.level-4 {
	> a {
		text-decoration: none;
		display: block;
		line-height: 1.3;
		color: $color_main11;
		padding: 0px 20px 0 20px;
		font-size: 14px;
		float: left;
		clear: both;
		line-height: 1.4;
		width: 100%;
		
		&:hover {
			text-decoration: underline;
		}
	}
}	
*/

.is-parent {
	/*
	> a {
		// background: url('../img/menu-marker-parent-white.png') right 20px center no-repeat;

		&:hover {
			background: url('../img/menu-marker-parent-yellow.png') right 20px center no-repeat;
		}
	}
	*/

	> ul {
		display: none;
	}
}

.is-active {
	> ul {
		display: block;
	}	
}

.is-collapsed {
	/*
	> a {
		background: url('../img/menu-marker-parent-yellow.png') right 20px center no-repeat;
		color: $colorYellow;
	}
	*/
	
	> ul {
		display: block;
	}
}		


.contacts__ico-menu {
	float: left;
	display: block;
	cursor: pointer;
	
	@include breakpoint (800px) {
		display: none;
	}
	
}


.social {
    text-align: center;
    padding-top: 10px;
    float: left;
    width: 100%;
}

.main {
    padding: 30px 30px 30px 20px;
    background-color: #f3db2d!important;
    color: $color_main11!important;
    width: 100%;
    float: left;
	font-weight: bold;
}


.activ_list {
    background:  url('../img/menu-marker-parent-white.png') 90% center no-repeat;
}


.jspPane {
    width: 100% !important;
}


.jspVerticalBar {
	background: none;
	width: 4px;
	right: 1px;
}

.jspTrack {
	background: none;
}

.jspDrag {
	background-color: #fff;
	opacity: 0.5;
	border-radius: 2px;
}