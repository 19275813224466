.news {

    &__title {
        margin-bottom: 30px;
    }

    &__item {
        display: flex;
        display: -ms-flexbox;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }

    &__item-img {
        flex: 0 0 20%;
        padding-right: 10px;

        img {
            max-width: 100%;
            font-style: italic;
            vertical-align: middle;
        }
    }    

    &__item-block {
        flex: 1 0 80%;
    }

    &__item-header {
        padding-bottom: 10px;
        display: flex;
    }

    &__item-title {
        font-size: 1.3em;
        padding-right: 10px;
        color: #2d3f65;        
    }

    &__item-date {
    }

    &__item-body {
        p {
            padding-top: 0;
        }
    }
}