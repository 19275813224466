.icon-links {
	@include container;
	
	.icon-links__item {
		@include span(12 of 12);
		
		// height: 160px;
		color: $color_main12;
		cursor: pointer;
		text-align: center;
		border: 2px solid $color_main5;
		overflow: hidden;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
		
		@include breakpoint (550px) {
			@include span(4 of 12);
			float: left;
		}
		
	
		img {
			max-width: 100%;
			// max-height: 120px;
			margin-top: 4px;
			display: none;
			
			@include breakpoint (550px) {
				display: inline;
			}
		}

		.title {
			@include container;
			padding-top: 10px;
			padding-bottom: 20px;
			text-align: center;
			text-transform: uppercase;
			color: $color_main11;
			font-weight: bold;
			font-size: 16px;
			margin-top: 10px;
			font-family: 'PT Sans', sans-serif;
			
			@include breakpoint (550px) {
				padding-bottom: 0;
			}
		}
		
		&:hover {
			background: url('../img/menu/tab_select.png') center top no-repeat;
		}
    }
	
	@include breakpoint (550px) {
		.last-list {
			@include span(4 of 12 last);
		}
	}	
}
