.poster {
    background-color: #2d3f65;
    height: 3em;
    width: 100%;
    position: relative;
    top: 0;
    padding: 4px;
    font-weight: 600;
    font-size: 150%;
    line-height: 1.3;
    border-bottom: px solid #002;
    text-align: center;

    @media (min-width: 798px){
        width: calc(100% - 240px);
        padding: 8px;
        left: 240px;
        font-size: 200%;
        border-top: 2px solid #ebebeb;
        
        @media (min-width: 1200px){
            // padding: 24px;
            height: 2em;
            width: 960px;
            font-size: 250%;
        }
    }
    

    &--link {
        color: #fff;

        &:hover { 
            color: #f3db2d;
        }
    }
}