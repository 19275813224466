.sidebar {
	top: 0px;
	left: 0px;
	min-height: 100%;
	height: 100%;
	color: $color_main12;
	width: 240px;
	position: fixed;
	background-color: $color_main1;
	z-index: 99;
	overflow: hidden;
}

.logo--sidebar {
	text-align: center;
	padding: 20px;
	// height: 190px;
	position: relative;
	z-index: 10;
	background-color: $color_main1;
	
	img {
		max-width: 50%;
		
		@include breakpoint (800px) {
			max-width: 100%;
		}
		
	}
}

.shadow-overlay {
  background-color: $color_main11; /* Чёрный фон */
  height: 100%; /* Высота максимальна */
  left: 0; /* Нулевой отступ слева */
  opacity: 0.5; /* Степень прозрачности */
  position: fixed; /* Фиксированное положение */
  top: 0; /* Нулевой отступ сверху */
  width: 100%; /* Ширина максимальна */
  z-index: 1; /* Заведомо быть НАД другими элементами */
}

