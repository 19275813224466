.contacts-map {
	height: $contactsMapHeight;
	width: $contactsMapWidth;
	background-color: $color_main10;
}

.contacts__cols {
	@include container();
}

.contacts__map {
	@include span(1 of 1);
	
	@include breakpoint(550px){
		@include span(1 of 2 1/12 after);
	}
}

.contacts__form {
	@include span(1 of 1);
	
	@include breakpoint(550px){
		@include span(1 of 2 1/12 after last);
	}	
}