// Простые теги
html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}


h3 {
	line-height: 25px;
}

h1, h2, h3, h4, h5, h6 {
	
}

a {
	color: #7B6AB4;
	text-decoration: none;
	
	&:hover {
		text-decoration: underline;
	}
}

body {
	font-family: Arial,Helvetica,sans-serif;
	
	//background-color: $color_main12;
	background-color: #f1f1f1;
	
	line-height: 18px;
	font-size: 14px;
}

/*
p {
	line-height: 18px;
}
*/

table { 
	max-width: 100%;
	border-collapse: collapse;
}

/* Zebra striping */
tr:nth-of-type(odd) {
	
}

th { 

}

td, th {

}