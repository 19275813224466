.main-content {
	padding: 15px 0;
}

.ico-content {
	background: url("../img/ico-content.png") no-repeat scroll -2px -2px #FCDB44;
	padding-left: 80px;
	padding-right: 20px;
	padding-bottom: 40px !important;
	font-weight: bold;
	padding-top: 22px !important;
}