/*
 * CSS Styles that are needed by jScrollPane for it to operate correctly.
 *
 * Include this stylesheet in your site or copy and paste the styles below into your stylesheet - jScrollPane
 * may not operate correctly without them.
 */

.jspContainer
{
	overflow: hidden;
	position: relative;
	margin-bottom: 100px;
}

.jspPane
{
	position: absolute;
}

.jspVerticalBar {

	position: absolute;
	/* top: 190px; */
	right: 0;
	width: 8px;
	height: 100%;
	background: #cecece none repeat scroll 0% 0%;
	opacity: 0.8;

	* {
		margin: 0;
		padding: 0;
	}

	.jspArrow
	{
		height: 16px;
	}
}

.jspHorizontalBar {

	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 16px;
	background: red;

	* {
		margin: 0;
		padding: 0;
	}
	.jspCap
	{
		float: left;
	}

	.jspTrack
	{
		float: left;
		height: 100%;
	}

	.jspDrag
	{
		float: left;
		height: 100%;
	}

	.jspArrow
	{
		width: 16px;
		float: left;
		height: 100%;
	}
}

.jspCap
{
	display: none;
}

.jspTrack
{
	position: relative;
}

.jspDrag
{
	background-color: #9AB1C7;
	position: relative;
	top: 0;
	left: 0;
	cursor: pointer;
	margin: 0px auto;
	border-radius: 16px;
	width: 8px;
}

.jspArrow
{
	background: #50506d;
	text-indent: -20000px;
	display: block;
	cursor: pointer;

	&.jspDisabled
	{
		cursor: default;
		background: #80808d;
	}
}

.jspVerticalBar .jspArrow:focus
{
	outline: none;
}

.jspCorner
{
	background: #eeeef4;
	float: left;
	height: 100%;
}

/* Yuk! CSS Hack for IE6 3 pixel bug :( */
* html .jspCorner
{
	margin: 0 -3px 0 0;
}