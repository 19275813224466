.contacts {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	@include breakpoint (800px) {
		flex-direction: row;
		align-items: flex-start;

	}
}

.contacts__block {
	display: flex;
	padding-bottom: 10px;

	a {
		color: inherit;
	}
}

.contacts__block-img {
	flex-shrink: 0;
	align-self: flex-start;
	margin-top: 5px;
	margin-right: 10px;
}

// .contacts {
// 	@include container;
// 	text-align: center;
// 	padding: 10px 0px 10px 0px;
// 	margin-bottom: 15px;
	
// 	.contacts__adress {
// 		@include container;
// 		text-align: center;
// 		margin-left: 35px;
// 		margin-bottom: 10px;
		
// 		a {
// 			text-decoration: none;
// 			color: #000;
			
// 			&:hover {
// 				text-decoration: underline;
// 			}
// 		}
// 	}

// 	.contacts__phone {
// 		@include container;
// 		text-align: center;
// 		margin-left: 35px;
// 		margin-bottom: 10px;
// 	}
	
// 	.contacts__phone-link {
// 		color: inherit;
// 	}

// 	.contacts__clock {
// 		@include container;
// 		text-align: center;
// 		margin-left: 35px;
// 		margin-bottom: 10px;
// 	}
	
// 	img { 
// 		border: 0px none;
// 		margin: 0px 10px 0px 0px;
// 		max-width: 100%;
// 	}
	
// 	table {
// 	    margin: 0px auto;
// 	}
	
// 	table.contact td {
// 		vertical-align: middle;
// 	}
	
	
// 	@include breakpoint (800px) {
	
// 		.phone {
// 			margin-left: 0px;
// 			margin-bottom: 0px;
// 		}
		
// 		img { 
// 			margin-left: 0px;
// 			margin-bottom: 0px;
// 		}	
// 	}
	
	
// 	@include breakpoint (1100px) {
	    
// 	    table.contact td{
// 		vertical-align: middle;
// 	    }
	    
// 		.contacts__adress {
// 			@include span(4 of 12);
// 			text-align: left;
// 			margin-left: 0px;
// 			margin-bottom: 0px;
// 		}
	
// 		.contacts__phone {
// 			@include span(last 4 of 12);
// 			text-align: right;
// 			margin-left: 0px;
// 			margin-bottom: 0px;
			
// 		}
		
// 		.contacts__clock {
// 			@include span(last 4 of 12);
// 			text-align: right;
// 			margin-left: 0px;
// 			margin-bottom: 0px;
			
// 			table {
// 			    float: right;
// 			}
// 		}
		
// 		img { 
// 			border: 0px none;
// 			margin: 0px 10px 0px 0px;
// 			max-width: 100%;
// 		}
// 	}
// }
