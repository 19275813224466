.img-links {
	@include container;
	.img-links__item {
		@include span(12 of 12);
		display: block;
		position: relative;
		color: $color_main12;
		cursor: pointer;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;

		
		&:hover {
			color: $color_main6;
		}
		
		@include breakpoint (550px) {
			@include span(4 of 12);
			float: left;
		}

		img {
			display: none;
			max-width: 100%;
			float: left;
			width: 100%;
			
			@include breakpoint (550px) {
				display: block;
			}
		}

		.title {
			float: left;
			background-color: $color_main7;
			opacity: 0.8;
			width: 100%;
			padding-top: 15px;
			padding-bottom: 20px;
			font-weight: bold;
			text-align: center;
			text-transform: uppercase;
			font-size: 14px;
			// margin-top: -45px;
			height: 45px;
			font-family: 'PT Sans', sans-serif;
			
			@include breakpoint (550px) {
				margin-top: -45px;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}
	
	@include breakpoint (550px) {
		.last-list {
			@include span(4 of 12 last);
		}
	}
}
