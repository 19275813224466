.breadcrumb {
	list-style: outside none none;
	font-size: 0.85em;
	margin-bottom: 0.75em;
	

	li {
		display: inline-block;
	}
	
	li::before {
		color: #2D3F65;
		content: "/";
	}
	
	li:first-child::before {
		content: "";
	}
	
	a {
		color: #2D3F65 !important;
	}
	
}
