.banner-big {
	@include container;
	display: none;
	position: relative;
	margin-bottom: 15px;
	
	@include breakpoint (800px) {
		display: block;
	}
	
	.banner-big__title {
	    font-size: 36px;
	    color: rgb(243, 219, 45);
	    font-weight: bold;
	    line-height: 1.028;
	    text-align: left;
	    text-shadow: 0px 3px 5px rgba(0, 1, 3, 0.82);
	    position: absolute;
	    left: 16px;
	    top: 30px;
		font-size: 24px;
	}
	
	@include breakpoint (1000px) {
		.banner-big__title {
			font-size: 36px;
		}
	}
	
	img {
		//@include container;
		width: 100%;
	}	
}
