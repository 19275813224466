$color_main7  :	#2d3f65; // Тёмно-синий
$color_main6  :	#f3db2d; // Жёлтый

$color_main3  :	#999999; // Тёмно-серый  в меню


$color_main1  :	#ebebeb;
$color_main2  :	#eac658;

$color_mail4  :	#cecece;
$color_main5  :	#d3d3d3;


$color_main12 :	#ffffff;
$color_main10 :	#ebebeb;
$color_main11 :	#000000;

$colorHannaBlue :#004276;


$colorBlue 		: $color_main7;
$colorYellow 	: $color_main6;
$colorWhite		: $color_main12;

$sidebarWidth: 240px;

$md: 760px;

// Your basic media queries, min-width and min/max width, are super easy!
$ExtraSmallDevices : 0px 768px;
$SmallDevices	   : 768px 992px;
$MediumDevices	   : 992px 1200px;
$LargeDevices	   : 1200px;

$fontHeaders	   : Cuprum;

$contactsMapHeight: 450px;
$contactsMapWidth: 100%;