.footer {
	@include span(12 of 12);
	background-color: $color_main12;
	border-top: 2px solid $color_mail4;
	margin-top: 4em;
	line-height: 100%;
	padding: 0.5em 0;
	font-size: 0.95em;
	color: $color_mail4;
	
	&__link {
		color: $color_mail4;
	}
}