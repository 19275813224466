$action3-breakpoint: 440px;

.action3 {
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	&__item {

		@include susy-breakpoint($action3-breakpoint) {
			width: 49%
		}
	}

	&__item-link {
		transition: all 0.5s;

		&:hover {
			opacity: 0.9;
		}

	}

	&__item-img {
		max-width: 100%;
	}

	@include susy-breakpoint($action3-breakpoint) {
		flex-direction: row;
	}
}
