.button {
	color: white;
	text-decoration: none;
	background-color: $colorHannaBlue;
	padding: 5px 12px;
	text-align: center;

	&:hover {
		text-decoration: none;
		color: $colorYellow;
	}

	&--action {
		margin-left: auto;
		margin-right: auto;
	}
}
