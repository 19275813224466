.gallery {
	@include container;
	margin-top: 20px;

	.gallery__item {
		@include span(12 of 12);

		border: 1px solid $color_main5;
		overflow: hidden;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
		vertical-align: middle;

		@include breakpoint (450px) {
			@include span(4 of 12);
			float: left;
		}

		img {
			max-width: 100%;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}

		&:nth-child(3n+3) {
			margin-right: 0;
		}
	}
}