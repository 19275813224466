.user-content {
	font-size: 1.2em;
	line-height: 150%;
		
	p {
		padding-bottom: 14px;
		padding-top: 14px;
	}
	
	ul {
		padding-left: 20px;
		padding-bottom: 14px;
		padding-top: 14px;
		list-style: outside;
	}
	
	p+ul {
		padding-top: 0;
	}
	
	img { 
		border: 0px none;
		max-width: 100%;
	}

	.lead {
		font-size: 1.1em;
		font-weight: bold;
	}
	
	img.left, 
	img.align_left, 
	img.align-left,
	figure.align_left, 
	figure.align-left {
		float: left;
		margin-top: 0;
		margin-left: 0;
		margin-right: 15px;
	}
	
	img.right,
	img.align_right,
	img.align-right,
	figure.align_right,
	figure.align-right {
		float: right;
		margin-left: 15px;
		margin-right: 0;
		margin-top: 0;
	}

	img.align_center,
	img.align-center,
	figure.align_center,
	figure.align-center {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	
	h1 {
		font-size: 1.8em;
		// margin-top: 1em;
		margin-bottom: 0.5em;
		padding-bottom: 0.4em;
		border-bottom: 2px solid $color_main7;
		color: $color_main7;
		font-weight: bold;
	}
	
	h2 {
		font-size: 1.5em;
		margin-top: 1em;
		margin-bottom: 0.5em;
		color: $color_main7;
	}
	
	strong {
		font-weight: bold;
	}

	table {
		border-collapse: collapse;
		width: 100%;
		margin: 15px 0 15px;
	}

	th, td {
		padding: 8px 4px 8px;

		@include susy-breakpoint($md){
			padding: 10px 12px 10px;
		}
	}

	td {
		&:last-child {
			text-align: center;
		}
	}

	th {
		background-color: $colorHannaBlue;
		color: white;
		font-weight: normal;

		&:first-child {
			text-align: left;
		}
	}
	
	tr {

		&:nth-child(even) {
			background-color: $color_main1;
		}
		
		&:last-child {
			border-bottom: 1px solid $color_main3;
		}
	}	
}

/*
.action {
	padding: 0 20px;
	margin-bottom: 20px;
	background-color: #F3DB2D;
	text-align: center;
	line-height: 1;
	vertical-align: middle;
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	font-size: 5em;
}
*/
