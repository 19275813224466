.popup {
	position: relative;
	background: #FFF;
	padding: 20px;
	width: auto;
	max-width: 800px;
	margin: 20px auto;
}

.alert {
	padding: 15px;
	font-size: 1.5em;
	background-color: #F3DB2D;
}