.header {
	display: flex;
	padding: 10px 0px;
	margin-bottom: 15px;

	&__menu-toggler {
		flex-shrink: 0;
		align-self: flex-start;
		// margin-right: 10px;
	}
}