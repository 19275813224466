.layout {
	width: 100%;
}

.page {
	@include container;
	// background-color: $color_main12;
	padding-left: $sidebarWidth;
}

.content {
	@include container(960px);
	float: left;
	width: 100%;
	padding: 10px;
	background-color: #fff;
	
	@include breakpoint (550px) {
		padding: 10px 30px;
	}
}